import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EditorAssetUrlService {
  buildMapUrlTemplate(mapconfigBaseUrl: string): string {
    return environment.backendUrl + '/file/' + mapconfigBaseUrl + '/{z}/{y}/{x}.png';
  }
}
