<nav class="navbar navbar-expand navbar-dark bg-dark" style="height: 50px">
  <a class="navbar-brand" routerLink="/maps">
    <img src="/assets/img/logo.png" height="36px" class="d-inline-block align-top mr-2" alt="" />
    <span class="text-light" style="line-height: 36px">Devtools</span>
  </a>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto"></ul>
    <div *ngIf="authService.isLoggedIn()" class="dropdown show">
      <a
        class="btn btn-link dropdown-toggle text-light"
        href="#"
        role="button"
        id="dropdownMenuLink"
        data-toggle="dropdown">
        <img
          src="/assets/img/avatar.png"
          height="32px"
          class="d-inline-block align-top mr-2 bg-white rounded-circle"
          alt="" />
        <span class="mr-1" style="line-height: 32px">{{ authService.getLoggedInUserName() }}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item" routerLink="" (click)="onLogoutClicked()">Logout</a>
      </div>
    </div>
  </div>
</nav>
