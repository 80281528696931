import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { MapViewComponent } from 'projects/editor/src/app/modules/editor/components/map-view/map-view.component';
import { Map, Circle, LatLng, LeafletMouseEvent, PathOptions, Popup, LatLngBounds, Rectangle, latLng } from 'leaflet';
import { EditorAssetUrlService } from 'projects/editor/src/app/modules/editor/services/editor-asset-url.service';
import { CaseRegion } from '../../models/case-spec.model';

@Component({
  selector: 'mima-click-region-map-view',
  templateUrl: '../map-view/map-view.component.html',
  styleUrls: ['../map-view/map-view.component.scss'],
})
export class ClickRegionMapViewComponent extends MapViewComponent {
  // tslint:disable-next-line:variable-name
  private _selectedRegionIndex: number | undefined;

  @Input() set selectedRegionIndex(selectedRegionIndex: number | undefined) {
    this._selectedRegionIndex = selectedRegionIndex;
    this.refreshMarkerStyles();

    this.updateSelectedRegionViewRect();
  }

  @Output() popupOpened = new EventEmitter<void>();

  @Output() popupAddRegionClicked = new EventEmitter<{ position: [number, number] }>();

  @Output() regionMoved = new EventEmitter<{ regionId: string; position: [number, number] }>();

  popup: Popup = new Popup();

  constructor(zone: NgZone, assetUrlService: EditorAssetUrlService) {
    super(zone, assetUrlService);
  }

  onMapReady(map: Map): void {
    super.onMapReady(map);

    // zoom out map after ready
    map.setView(map.options.center!, 2);
  }

  onMapClicked($event: LeafletMouseEvent): void {
    this.popupOpened.emit();

    if (this.map) {
      this.popup
        .setLatLng($event.latlng)
        .setContent(`<button id="popupButton" class="btn btn-primary">Klickregion anlegen</button>`)
        .openOn(this.map);

      this.popup
        .getElement()
        ?.querySelector('#popupButton')
        ?.addEventListener('click', () => {
          this.onPopupButtonClicked($event.latlng);
        });
    }
  }

  onPopupButtonClicked(latlng: LatLng): void {
    this.popupAddRegionClicked.emit({ position: [latlng.lat, latlng.lng] });
    this.map?.closePopup(this.popup);
  }

  getMarkerStyle(index: number): PathOptions {
    return {
      color: 'none',
      fillColor: '#f00',
      fillOpacity: this._markersVisible ? (this._selectedRegionIndex === index ? 0.5 : 0.2) : 0,
    };
  }

  onMapRegionCreated(region: CaseRegion, index: number, circle: Circle): void {
    const onMapMouseMove = (moveEvent: LeafletMouseEvent) => {
      circle.setLatLng(moveEvent.latlng);
    };

    circle.on('mousedown', () => {
      if (this._selectedRegionIndex === index) {
        this.map?.on('mousemove', onMapMouseMove);
        this.map?.dragging.disable();

        // remove selected Region View Rect on drag
        if (this.selectedRegionViewRect) {
          this.selectedRegionViewRect.remove();
          this.selectedRegionViewRect = undefined;
        }
      }
    });
    circle.on('mouseup', () => {
      this.map?.off('mousemove', onMapMouseMove);
      this.map?.dragging.enable();
      this.zone.run(() => {
        this.regionMoved.emit({
          regionId: region.id,
          position: [Math.round(circle.getLatLng().lat), Math.round(circle.getLatLng().lng)],
        });
      });

      // drag complte -> add sel. region v.r.
      this.updateSelectedRegionViewRect();
    });
  }

  updateRegions(regions: CaseRegion[]): void {
    // this will reset markerLayers
    super.updateRegions(regions);

    this.selectedRegionViewRect = undefined;
    this.updateSelectedRegionViewRect();
  }

  private selectedRegionViewRect: Rectangle | undefined;

  updateSelectedRegionViewRect() {
    if (this._selectedRegionIndex !== undefined) {
      if (this._regions) {
        const region = this._regions[this._selectedRegionIndex];

        // add view Rect
        const rectBounds = new LatLngBounds(
          latLng(region.center[0] + region.viewBounds[0], region.center[1] + region.viewBounds[1]),
          latLng(region.center[0] + region.viewBounds[2], region.center[1] + region.viewBounds[3])
        );

        if (this.selectedRegionViewRect) {
          this.selectedRegionViewRect.remove();
          this.selectedRegionViewRect = undefined;
        }
        this.selectedRegionViewRect = new Rectangle(rectBounds, {
          color: '#0078ff',
          weight: 3,
          fill: false,
          interactive: false,
        });
        this.markerLayers.push(this.selectedRegionViewRect);
      }
    }
  }
}
