import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateMapPageComponent } from './components/create-map-page/create-map-page.component';
import { MapListPageComponent } from './components/map-list-page/map-list-page.component';
import { ExploreMapPageComponent } from './components/explore-map-page/explore-map-page.component';
import { CreateCasePageComponent } from './components/create-case-page/create-case-page.component';
import { EditCasePageComponent } from './components/edit-case-page/edit-case-page.component';
import { PendingChangesGuard } from '../editor-shared/guards/pending-changes.guard';
import { EditMapPageComponent } from './components/edit-map-page/edit-map-page.component';
import { oAuthGuard } from '../auth/guards/o-auth.guard';
import { roleGuard } from '../auth/guards/role.guard';

const routes: Routes = [
  {
    path: 'maps',
    component: MapListPageComponent,
    canActivate: [oAuthGuard, roleGuard('EDITOR')],
  },
  {
    path: 'map/:id/explore',
    component: ExploreMapPageComponent,
    canActivate: [oAuthGuard, roleGuard('EDITOR')],
    data: {
      fullScreen: true,
    },
  },
  {
    path: 'map/create',
    component: CreateMapPageComponent,
    canActivate: [oAuthGuard, roleGuard('EDITOR')],
  },
  {
    path: 'map/:id',
    component: EditMapPageComponent,
    canActivate: [oAuthGuard, roleGuard('EDITOR')],
  },
  {
    path: 'map/:mapId/case/create',
    canDeactivate: [PendingChangesGuard],
    canActivate: [oAuthGuard, roleGuard('EDITOR')],
    component: CreateCasePageComponent,
  },
  {
    path: 'map/:mapId/case/:caseId',
    canActivate: [oAuthGuard, roleGuard('EDITOR')],
    canDeactivate: [PendingChangesGuard],
    component: EditCasePageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EditorRoutingModule {}
