<div *ngIf="uploading" class="col-form-label">
  <span class="spinner-border spinner-border-sm" role="status"></span>
  <span class="ml-2">{{ uploadingText }}</span>
</div>

<div *ngIf="!uploading && fileUpload == null" class="col-form-label">
  <input
    type="file"
    class="form-control-file"
    (change)="onFileChange($event)"
    [accept]="accept"
    [disabled]="disabled || uploading" />
</div>

<div *ngIf="!uploading && fileUpload != null" class="col-form-label d-flex align-items-center">
  <span>{{ uploadedFileText }} {{ fileUpload.filename }}</span>
  <button class="ml-2 btn btn-link text-danger p-0" (click)="onRemove()" [disabled]="disabled">{{ removeText }}</button>
</div>
