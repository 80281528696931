import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { ImprintAndDataProtectionPageComponent } from './components/imprint-and-data-protection-page/imprint-and-data-protection-page.component';
import { IndexPageComponent } from './components/index-page/index-page.component';

const routes: Routes = [
  {
    path: '',
    component: IndexPageComponent,
    data: {
      fullScreen: true,
    },
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'imprint-and-data-protection',
    component: ImprintAndDataProtectionPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
