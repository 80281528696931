<div *ngIf="maps; else loading" class="mt-5">
  <div class="d-flex justify-content-between mb-3">
    <div><h2>Stadtkarten</h2></div>
    <div>
      <button class="btn btn-primary" (click)="onCreateNewCardClicked()">
        Neue Karte anlegen
        <i class="ml-2 bi bi-plus-circle-fill"></i>
      </button>
    </div>
  </div>
  <div *ngFor="let map of maps" class="card rounded shadow-sm border-0 mb-3">
    <div class="row no-gutters">
      <div class="col-md-3" style="min-height: 100px">
        <img
          *ngIf="map.state === 'PROCESSED'"
          [src]="getMapPreviewImageUrl(map)"
          class="card-img w-100 h-100"
          style="object-fit: cover"
          alt="Map Preview" />
        <div
          *ngIf="map.state !== 'PROCESSED'"
          class="card-img w-100 h-100 text d-flex justify-content-center align-items-center">
          <h1 *ngIf="map.state === 'FAILED'"><i class="bi bi-bug-fill text-muted"></i></h1>
          <h1 *ngIf="map.state === 'QUEUED'"><i class="bi bi-hourglass-split text-muted"></i></h1>
          <span *ngIf="map.state === 'PROCESSING'" class="spinner-border text-muted"></span>
        </div>
      </div>
      <div class="col-md-9">
        <div class="card-body">
          <h3 class="card-title">{{ map.name }}</h3>
          <dl class="row">
            <dt class="col-6 col-lg-4">Erstellungsdatum</dt>
            <dd class="col-6 col-lg-6">{{ map.created | date: 'medium' }}</dd>
            <dt class="col-6 col-lg-4">Verarbeitungsstatus</dt>
            <dd class="col-6 col-lg-6">{{ map.state }}</dd>
          </dl>

          <div class="d-flex flex-wrap gap-2">
            <button
              *ngIf="map.state === 'PROCESSED'"
              class="btn btn-light border shadow-none text-primary"
              [routerLink]="['/map', map.id, 'explore']">
              Erkunden
            </button>
            <button
              *ngIf="map.state === 'PROCESSED' || map.state === 'FAILED'"
              class="btn btn-light border shadow-none text-primary"
              (click)="onEditClicked(map)">
              Karte bearbeiten
            </button>
            <button
              *ngIf="map.state === 'PROCESSED'"
              class="btn btn-light border shadow-none text-primary"
              (click)="onExportMapSourcePdfClicked(map)">
              PDF exportieren
            </button>
            <button
              *ngIf="map.state === 'PROCESSED'"
              class="btn btn-light border shadow-none text-primary"
              (click)="onExportMapClicked(map)">
              Kartendaten exportieren
            </button>
            <button
              *ngIf="map.state === 'PROCESSED' || map.state === 'FAILED'"
              class="btn btn-light border shadow-none text-primary"
              (click)="onDeleteClicked(map)">
              Löschen
            </button>
          </div>

          <div *ngIf="map.state === 'PROCESSED'" class="mt-4">
            <div class="btn btn-link p-0 mb-2" (click)="onCollapseCasesClicked(map)">
              <h4 class="d-inline">Fälle</h4>
              <i class="bi bi-caret-down-fill pl-2" [class.rotated]="collapsedCasesMapIds.has(map.id)"></i>
            </div>
            <div [collapse]="!collapsedCasesMapIds.has(map.id)" [isAnimated]="true">
              <div *ngFor="let caseEntity of map.cases" class="card mb-2">
                <div class="card-body align-items-center justify-content-between d-flex flex-wrap gap-2 p-2">
                  <div class="pr-3 pl-1">
                    <h5 class="mb-0">{{ caseEntity.title }}</h5>
                  </div>
                  <div class="d-flex flex-wrap gap-2">
                    <button
                      class="btn btn-light border shadow-none text-primary"
                      (click)="onEditCaseClicked(map, caseEntity)">
                      Bearbeiten
                    </button>
                    <button
                      class="btn btn-light border shadow-none text-primary"
                      (click)="onDeleteCaseClicked(map, caseEntity)">
                      Löschen
                    </button>
                    <button
                      *ngIf="map.state === 'PROCESSED'"
                      class="btn btn-light border shadow-none text-primary"
                      (click)="onShowPlayLink(map, caseEntity)">
                      Spielen
                    </button>
                    <button
                      class="btn btn-light border shadow-none text-primary"
                      (click)="onExportCaseClicked(map, caseEntity)">
                      <i class="bi bi-download"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <button
                  class="btn btn-light border shadow-none w-100 text-primary mr-2"
                  [routerLink]="['/map', map.id, 'case', 'create']">
                  Neuen Fall anlegen
                  <i class="ml-2 bi bi-plus-circle-fill"></i>
                </button>
                <button class="btn btn-light border shadow-none w-100 text-primary" (click)="onImportCaseClicked(map)">
                  Fall importieren
                  <i class="ml-2 bi bi-upload"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex flex-column align-items-center mt-5">
    <div class="spinner-grow" role="status"></div>
    <h3 class="mt-3">Karten werden geladen...</h3>
  </div>
</ng-template>
