import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../../../../environments/environment';
import { TesterCredentialsService } from '../../services/tester-credentials.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mima-tester-link-modal',
  templateUrl: './play-link-modal.component.html',
  styleUrls: ['./play-link-modal.component.scss'],
})
export class PlayLinkModalComponent {
  mapId: number | undefined;
  caseId: number | undefined;

  testerLink$ = this.testerCredentialsService
    .getTesterCredentials()
    .pipe(
      map(
        credentials =>
          `${environment.gameUrl}?mapId=${this.mapId}&caseId=${this.caseId}&authCredentials=${credentials.basicAuth}`
      )
    );

  constructor(
    private modalRef: BsModalRef,
    private testerCredentialsService: TesterCredentialsService
  ) {}

  onCloseClicked(): void {
    this.modalRef.hide();
  }
}
