<div class="modal-header">
  <h4 class="modal-title pull-left">{{ titleText }}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseClicked()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{ bodyText }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary mr-2" (click)="onConfirmClicked()">{{ confirmButtonText }}</button>
  <button type="button" class="btn btn-light border shadow-none text-primary" (click)="onAbortClicked()">
    {{ abortButtonText }}
  </button>
</div>
