import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MapService } from '../../services/map.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mima-create-map-page',
  templateUrl: './create-map-page.component.html',
  styleUrls: ['./create-map-page.component.scss'],
})
export class CreateMapPageComponent {
  form = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    sourcePdf: new UntypedFormControl(null),
  });

  constructor(
    private mapService: MapService,
    private router: Router
  ) {}

  onSubmit(): void {
    this.mapService.createMap(this.form.value).subscribe({
      complete: () => {
        this.router.navigate(['maps']).then();
      },
      error: (error: any) => {
        console.log('error creating map: ' + error);
      },
    });
  }
}
