import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileUpload } from '../models/file-upload.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private httpClient: HttpClient) {}

  upload(file: File): Observable<FileUpload> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<FileUpload>(`${environment.backendUrl}/uploads`, formData);
  }
}
