<div class="modal-header">
  <h4 class="modal-title pull-left">Spielen</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseClicked()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="directLink">Direktlink für Testspieler</label>
    <div class="input-group">
      <input type="text" class="form-control" id="directLink" value="{{ testerLink$ | async }}" disabled />
      <div class="input-group-append">
        <button
          class="btn btn-light border shadow-none text-primary"
          [cdkCopyToClipboard]="(testerLink$ | async) || ''"
          tooltip="Kopiert!"
          triggers="focus">
          Kopieren
        </button>
      </div>
    </div>
  </div>
  <div class="mt-4 w-100 d-flex">
    <a class="btn btn-outline-primary flex-grow-1" [href]="(testerLink$ | async) || ''" target="_blank">
      In neuem Tab öffnen
    </a>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary mr-2" (click)="onCloseClicked()">Schließen</button>
</div>
