<nav>
  <ol class="breadcrumb bg-transparent mt-1 mb-1 pl-0">
    <li class="breadcrumb-item"><a routerLink="/maps">Stadtkarten</a></li>
    <li class="breadcrumb-item">Testkarte</li>
    <li class="breadcrumb-item active">Fall anlegen</li>
  </ol>
</nav>

<mima-case-editor *ngIf="mapConfig$ | async as mapConfig" [mapConfig]="mapConfig" [(caseSpec)]="caseSpec">
</mima-case-editor>

<button class="btn btn-primary mt-2 mb-4" (click)="onCreateCaseClicked()">Neuen Fall anlegen</button>
