import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class TesterBasicAuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isBackendUrl = request.url.startsWith(environment.backendUrl);

    const testerCredentialsParam = this.route.snapshot.queryParamMap.get('testerCredentials');
    if (testerCredentialsParam) {
      this.authService.updateTesterCredentials(testerCredentialsParam);
    }

    if (!this.authService.isLoggedIn() && this.authService.hasTesterCredentials() && isBackendUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${this.authService.getTesterCredentials()}`,
        },
      });
    }
    return next.handle(request);
  }
}
