import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MapService } from '../../services/map.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { MapConfigModel } from '../../models/map-config.model';
import { MapModel } from '../../models/map.model';

@Component({
  selector: 'mima-explore-map-page',
  templateUrl: './explore-map-page.component.html',
  styleUrls: ['./explore-map-page.component.scss'],
})
export class ExploreMapPageComponent {
  mapConfig$: Observable<MapConfigModel | null>;

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService,
    private router: Router
  ) {
    this.mapConfig$ = this.route.params.pipe(
      map((params: Params) => params.id),
      mergeMap((id: string) => mapService.getMap(Number.parseInt(id))),
      map((mapModel: MapModel) => mapModel.config)
    );
  }

  onReturnButtonClicked(): void {
    this.router.navigate(['maps']).then();
  }
}
