<nav>
  <ol class="breadcrumb bg-transparent mt-1 mb-1 pl-0">
    <li class="breadcrumb-item"><a routerLink="/maps">Stadtkarten</a></li>
    <li class="breadcrumb-item">{{ (map$ | async)?.name }}</li>
    <li class="breadcrumb-item">{{ (case$ | async)?.spec?.description?.title }}</li>
    <li class="breadcrumb-item active">Fall bearbeiten</li>
  </ol>
</nav>

<div *ngIf="loaded; else loading">
  <mima-case-editor
    *ngIf="map$ | async as map"
    [mapConfig]="map.config"
    [(caseSpec)]="caseSpec"
    (caseSpecChange)="onCaseSpecChange($event)">
  </mima-case-editor>

  <div *ngIf="unsavedChanges" style="color: red">Änderungen nicht gespeichert</div>

  <button class="btn btn-primary mt-2 mr-2 mb-4" (click)="onApplyChangesClicked()">Änderungen übernehmen</button>
  <button class="btn btn-primary mt-2 mr-2 mb-4" (click)="onSaveChangesClicked()">Speichern und Zurück</button>
  <button class="btn btn-secondary mt-2 mb-4" (click)="onBackClicked()">Verwerfen</button>
</div>

<ng-template #loading>
  <div class="d-flex flex-column align-items-center mt-5">
    <div class="spinner-grow" role="status"></div>
    <h3 class="mt-3">Fall wird geladen...</h3>
  </div>
</ng-template>
