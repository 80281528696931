import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import {
  Circle,
  CRS,
  DomEvent,
  LatLngBounds,
  LeafletEvent,
  LeafletMouseEvent,
  Map,
  MapOptions,
  Path,
  PathOptions,
  point,
  Projection,
  TileLayer,
  tileLayer,
  Transformation,
  Util,
} from 'leaflet';

import { MapConfigModel } from '../../models/map-config.model';
import { CaseRegion } from '../../models/case-spec.model';
import extend = Util.extend;
import { EditorAssetUrlService } from '../../services/editor-asset-url.service';

@Component({
  selector: 'mima-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent {
  // tslint:disable-next-line:variable-name
  protected _mapConfig: MapConfigModel | undefined;

  // tslint:disable-next-line:variable-name
  protected _markersVisible = true;

  @Input() set mapConfig(mapConfig: MapConfigModel | undefined) {
    this._mapConfig = mapConfig;
    if (mapConfig) {
      this.updateForMapConfig(mapConfig);
    }
  }

  // TODO: ok?
  @Output() viewMapReady = new EventEmitter<{ map: Map }>();

  protected _regions: CaseRegion[] | null | undefined;

  @Input() set regions(regions: CaseRegion[] | null | undefined) {
    this.updateRegions(regions);

    this._regions = regions;
  }

  @Input() set markersVisible(markersVisible: boolean) {
    this._markersVisible = markersVisible;
    this.refreshMarkerStyles();
  }

  @Input()
  clickInfoVisible = false;

  @Input() set showZoomControl(showZoomControl: boolean) {
    this.options.zoomControl = false;
  }

  @Output() regionClicked = new EventEmitter<{ regionId: string }>();

  @Output() tilesLoaded = new EventEmitter<{}>();

  mapLayer: TileLayer | undefined;
  markerLayers: Path[] = [];
  clickInfoLayer: Path | undefined;

  map: Map | undefined;

  options = MapViewComponent.createOptions();

  constructor(
    protected zone: NgZone,
    protected assetUrlService: EditorAssetUrlService
  ) {}

  private static createCRS(maxZoom: number): CRS {
    const minResolution = Math.pow(2.0, maxZoom);
    return extend({}, CRS.Simple, {
      projection: Projection.LonLat,
      transformation: new Transformation(1, 0, 1, 0),
      scale: (zoom: number) => Math.pow(2, zoom) / minResolution,
      zoom: (scale: number) => Math.log(scale * minResolution) / Math.LN2,
    });
  }

  private static createOptions(): MapOptions {
    const minZoom = 0;
    const maxZoom = 1;
    const crs = this.createCRS(0);
    return {
      crs,
      minZoom,
      maxZoom,
      zoomSnap: 0,
      zoomDelta: 0.25,
      wheelPxPerZoomLevel: 100,
      bounceAtZoomLimits: false,
      center: [0.5, 0.5],
      zoom: 4,
      maxBounds: [
        [0, 0],
        [1, 1],
      ],
      maxBoundsViscosity: 0,
      layers: [],
      attributionControl: false,
    };
  }

  protected updateForMapConfig(mapConfig: MapConfigModel): void {
    let maxNativeZoom = Math.ceil(Math.log2(Math.max(mapConfig.width, mapConfig.height) / mapConfig.tileSize));
    const maxZoom = maxNativeZoom + 1;
    this.options.crs = MapViewComponent.createCRS(maxNativeZoom);

    const boundsWidth = Math.max(mapConfig.width, mapConfig.height);
    const boundsExpand = boundsWidth * 0.25;

    const maxNativeBounds = new LatLngBounds(
      this.options.crs.unproject(point(0, 0)),
      this.options.crs.unproject(point(boundsWidth, boundsWidth))
    );

    const maxBounds = new LatLngBounds(
      this.options.crs.unproject(point(-boundsExpand, -boundsExpand)),
      this.options.crs.unproject(point(boundsWidth + boundsExpand, boundsWidth + boundsExpand))
    );

    const center = maxBounds.getCenter();

    this.options.maxZoom = maxZoom;
    this.options.minZoom = -2;
    this.options.maxBounds = maxBounds;
    this.options.center = center;

    /* dpr handling, retina etc. */
    /* TODO: check */
    let tileSize: number = mapConfig.tileSize;
    let zoomOffset = 0;

    let ratio = Math.round(window.devicePixelRatio);
    if (ratio < 1) {
      ratio = 1;
    } else if (ratio > 4) {
      ratio = 4;
    }

    console.log('using dp ratio: ' + ratio);

    if (ratio == 1) {
      tileSize = mapConfig.tileSize;
    } else if (ratio == 2) {
      tileSize = Math.floor(mapConfig.tileSize / 2);
      zoomOffset += 1;
      maxNativeZoom -= 1;
      this.options.maxZoom = Math.max(this.options.minZoom, this.options.maxZoom - 1);
      this.options.minZoom = Math.max(0, this.options.minZoom);
    } else if (ratio == 3) {
      // TODO: check esp. for this DPR
      tileSize = Math.floor(mapConfig.tileSize / 4);
      zoomOffset += 2; // ??
      maxNativeZoom -= 2;
      this.options.maxZoom = Math.max(this.options.minZoom, this.options.maxZoom - 2); // ?
      this.options.minZoom = Math.max(0, this.options.minZoom);
    } else if (ratio == 4) {
      tileSize = Math.floor(mapConfig.tileSize / 4);
      zoomOffset += 2; // ??
      maxNativeZoom -= 2;
      this.options.maxZoom = Math.max(this.options.minZoom, this.options.maxZoom - 2);
      this.options.minZoom = Math.max(0, this.options.minZoom);
    } else {
      console.log('map-view: invalid pixel ratio');
    }

    this.mapLayer = tileLayer(this.assetUrlService.buildMapUrlTemplate(mapConfig.baseUrl), {
      detectRetina: false,
      minZoom: this.options.minZoom,
      minNativeZoom: 0,
      maxZoom: this.options.maxZoom,
      maxNativeZoom: maxNativeZoom,
      noWrap: true,
      tms: false,
      zoomOffset: zoomOffset,
      tileSize: tileSize,
      bounds: maxNativeBounds,
      updateWhenIdle: false, // defaults to true for mobile, but we want quick loading
      updateWhenZooming: true,
      updateInterval: 200, // default is 200
      keepBuffer: 2, // default is 2
    });

    this.mapLayer.on('load', () => {
      this.tilesLoaded.emit({});
    });

    if (this.map != null) {
      this.map.invalidateSize();
    }
  }

  protected updateRegions(regions: CaseRegion[] | null | undefined): void {
    if (!regions) {
      this.markerLayers = [];
    } else {
      this.markerLayers = regions.flatMap((region, index) => {
        const circle = new Circle(region.center, {
          radius: region.radius,
        });
        circle.setStyle(this.getMarkerStyle(index));
        circle.on('click', event => {
          DomEvent.stopPropagation(event);
          this.zone.run(() => {
            this.regionClicked.emit({ regionId: region.id });
          });
        });
        this.onMapRegionCreated(region, index, circle);

        return circle;
      });
    }
  }

  protected refreshMarkerStyles(): void {
    this.markerLayers.forEach((markerLayer, index) => {
      markerLayer.setStyle(this.getMarkerStyle(index));
    });
  }

  onMapReady(map: Map): void {
    // Save map reference
    this.map = map;

    this.viewMapReady.emit({ map: map });

    setTimeout(() => {
      map.invalidateSize();
      this.onMapReadyAfterInvalidateSize(map);
    }, 500);
  }

  onMapReadyAfterInvalidateSize(map: Map): void {}

  onMapRegionCreated(region: CaseRegion, index: number, circle: Circle): void {}

  protected onMapClicked($event: LeafletMouseEvent): void {
    this.clickInfoLayer = new Circle($event.latlng, { radius: 300 });
    this.clickInfoLayer.setStyle({
      color: 'none',
      fillColor: '#777',
      fillOpacity: 0.2,
    });
    setTimeout(() => {
      this.clickInfoLayer = undefined;
    }, 1000);
  }

  getMarkerStyle(index: number): PathOptions {
    return {
      color: 'none',
      fillColor: '#f00',
      fillOpacity: this._markersVisible ? 0.2 : 0,
    };
  }

  protected onMapMoveEnd($event: LeafletEvent) {}

  protected onMapZoomEnd($event: LeafletEvent) {}

  protected onMapMoveStart($event: LeafletEvent) {}

  protected onMapZoomStart($event: LeafletEvent) {}
}
