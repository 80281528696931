import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TesterCredentialsModel } from '../models/tester-credentials.model';

@Injectable({
  providedIn: 'root',
})
export class TesterCredentialsService {
  constructor(private httpClient: HttpClient) {}

  getTesterCredentials(): Observable<TesterCredentialsModel> {
    return this.httpClient.get<TesterCredentialsModel>(`${environment.backendUrl}/tester-credentials`);
  }
}
