<div
  leaflet
  [leafletOptions]="options"
  (leafletMapReady)="onMapReady($event)"
  (leafletClick)="onMapClicked($event)"
  id="map"
  class="bg-white"
  (leafletMapMoveStart)="onMapMoveStart($event)"
  (leafletMapZoomStart)="onMapZoomStart($event)"
  (leafletMapMoveEnd)="onMapMoveEnd($event)"
  (leafletMapZoomEnd)="onMapZoomEnd($event)">
  <div *ngIf="mapLayer" [leafletLayer]="mapLayer!"></div>
  <div *ngFor="let markerLayer of markerLayers" [leafletLayer]="markerLayer"></div>
  <div *ngIf="clickInfoLayer && clickInfoVisible" [leafletLayer]="clickInfoLayer!"></div>
</div>
