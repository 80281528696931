import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CaseRegion } from '../../models/case-spec.model';

@Component({
  selector: 'mima-click-region-list',
  templateUrl: './click-region-list.component.html',
  styleUrls: ['./click-region-list.component.scss'],
})
export class ClickRegionListComponent {
  @Input()
  regions: CaseRegion[] | null | undefined;

  @Input()
  selectedRegion: CaseRegion | undefined;

  @Output() regionClicked = new EventEmitter<{ regionId: string }>();

  onRegion(region: CaseRegion) {
    this.regionClicked.emit({ regionId: region.id });
  }
}
