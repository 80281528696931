import { EditorSharedModule } from '../editor-shared/editor-shared.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { CreateMapPageComponent } from './components/create-map-page/create-map-page.component';
import { MapListPageComponent } from './components/map-list-page/map-list-page.component';
import { ExploreMapPageComponent } from './components/explore-map-page/explore-map-page.component';
import { EditorRoutingModule } from './editor-routing.module';
import { CaseEditorComponent } from './components/case-editor/case-editor.component';
import { CreateCasePageComponent } from './components/create-case-page/create-case-page.component';
import { EditCasePageComponent } from './components/edit-case-page/edit-case-page.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickRegionPanelComponent } from './components/click-region-panel/click-region-panel.component';
import { ClickRegionMapViewComponent } from './components/click-region-map-view/click-region-map-view.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PlayLinkModalComponent } from './components/play-link-modal/play-link-modal.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { EditMapPageComponent } from './components/edit-map-page/edit-map-page.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ImportCaseModalComponent } from './components/import-case-modal/import-case-modal.component';
import { ClickRegionListComponent } from './components/click-region-list/click-region-list.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

@NgModule({
  declarations: [
    CaseEditorComponent,
    CreateCasePageComponent,
    CreateMapPageComponent,
    EditCasePageComponent,
    ExploreMapPageComponent,
    MapListPageComponent,
    ClickRegionPanelComponent,
    ClickRegionMapViewComponent,
    PlayLinkModalComponent,
    EditMapPageComponent,
    ImportCaseModalComponent,
    ClickRegionListComponent,
    MapViewComponent,
  ],
  imports: [
    CommonModule,
    EditorSharedModule,
    EditorRoutingModule,
    FileUploadModule,
    NgSelectModule,
    ReactiveFormsModule,
    DragDropModule,
    ClipboardModule,
    TooltipModule,
    CollapseModule,
    LeafletModule,
  ],
})
export class EditorModule {}
