<div class="mt-4">
  <h2 class="mb-3">Falleinstellungen</h2>
  <div class="bg-white rounded shadow-sm">
    <div class="card-body">
      <form [formGroup]="caseForm">
        <div class="form-group row">
          <label for="caseTitle" class="col-sm-2 col-form-label">Titel</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="caseTitle" formControlName="title" />
          </div>
        </div>
        <div class="form-group row">
          <label for="caseDifficulty" class="col-sm-2 col-form-label">Schwierigkeitsgrad</label>
          <div class="col-sm-10">
            <input type="number" class="form-control" id="caseDifficulty" formControlName="difficulty" />
          </div>
        </div>
        <div class="form-group row mb-0">
          <label for="tags" class="col-sm-2 col-form-label">Tags</label>
          <div class="col-sm-10">
            <ng-select [multiple]="true" [addTag]="onAddTag.bind(this)" id="tags" formControlName="tags" />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<h2 class="mt-4 mb-3">Klickregionen</h2>
<p>Klicken zum Anlegen von Klickregionen (für Lösungen, Tooltips)</p>

<div class="container shadow-sm rounded overflow-hidden">
  <div class="row">
    <mima-click-region-map-view
      *ngIf="mapConfig"
      [mapConfig]="mapConfig"
      [regions]="caseSpec.regions"
      [markersVisible]="true"
      [selectedRegionIndex]="selectedRegionIndex"
      (viewMapReady)="onClickRegionMapReady($event)"
      (regionClicked)="onRegionClicked($event)"
      (regionMoved)="onRegionMoved($event)"
      (popupOpened)="onPopupOpened()"
      (popupAddRegionClicked)="onPopupAddRegionClicked($event)"
      class="col-12 p-0"
      style="height: 500px">
    </mima-click-region-map-view>
  </div>
  <div class="row border-top" style="height: 400px">
    <mima-click-region-list
      *ngIf="mapConfig"
      [regions]="caseSpec.regions"
      [selectedRegion]="selectedRegion"
      (regionClicked)="onRegionClicked($event)"
      class="col-3 p-0 border-right bg-white h-100">
    </mima-click-region-list>
    <mima-click-region-panel
      *ngIf="selectedRegion !== undefined"
      [region]="selectedRegion"
      [allowDelete]="isRegionDeletable(selectedRegion)"
      [takenIds]="getNonSelectedRegionIds()"
      (regionChanged)="onRegionChanged($event)"
      (regionDeleteClicked)="onRegionDeleteClicked($event)"
      (regionCloseClicked)="onRegionCloseClicked()"
      class="col-9 bg-white">
    </mima-click-region-panel>
  </div>
</div>

<div class="mt-4">
  <h2 class="mb-3">Falldefinition</h2>
  <div class="bg-white rounded shadow-sm mb-3">
    <div class="d-flex p-3 border-bottom">
      <nav class="mr-2 flex-grow-1" style="min-width: 0">
        <div
          cdkDropList
          [cdkDropListOrientation]="'horizontal'"
          (cdkDropListDropped)="drop($event)"
          class=""
          style="display: flex; flex-direction: row; flex-wrap: nowrap">
          <div class="p-2 bg-light mr-2 rounded" style="min-width: 0; flex: 1 1 0">
            <div class="pb-2 text-center">Titel</div>
            <div
              class="rounded bg-white border-primary"
              [class.border]="selectedCardIndex === TITLE_CARD_INDEX"
              (click)="onCardSelected(TITLE_CARD_INDEX)">
              <div class="p-1 text-center" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                {{ caseSpec.description.title }}
              </div>
            </div>
          </div>
          <div
            *ngFor="let card of caseSpec.cards; index as i"
            class="p-2 bg-light mr-2 rounded"
            style="min-width: 0; flex: 1 1 0">
            <div class="pb-2 text-center">{{ i + 1 }}</div>
            <div
              cdkDrag
              class="rounded bg-white border-primary"
              [class.border]="selectedCardIndex === i"
              (click)="onCardSelected(i)">
              <div class="p-1 text-center" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                {{ card.frontTitle }}<br />
                <i class="bi bi-grip-horizontal"></i>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <button class="btn btn-light border shadow-none text-primary" (click)="onAddCardClicked()">
        Frage<br />hinzufügen<br />
        <i class="mt-1 bi bi-plus-circle-fill"></i>
      </button>
    </div>

    <!-- title card -->
    <div *ngIf="selectedCardIndex === TITLE_CARD_INDEX" class="card-body">
      <form [formGroup]="titleCardForm">
        <h4 class="mb-2">Titelseite</h4>

        <!-- viewRegion for title card-->
        <form formGroupName="viewRegion">
          <div class="form-group row">
            <label for="titleCardViewRegionId" class="col-sm-2 col-form-label">Region für Startansicht</label>
            <div class="col-sm-10">
              <ng-select [multiple]="false" formControlName="regionId" id="titleCardViewRegionId">
                <ng-option *ngFor="let region of caseSpec.regions" [value]="region.id">{{ region.id }}</ng-option>
              </ng-select>
            </div>
          </div>
        </form>

        <div class="form-group row">
          <label for="titleCardBackText" class="col-sm-2 col-form-label">Einführungstext</label>
          <div class="col-sm-10">
            <textarea class="form-control" id="titleCardBackText" formControlName="backText" rows="3"></textarea>
          </div>
        </div>
      </form>
    </div>

    <!-- cards -->

    <div *ngIf="selectedCardIndex !== TITLE_CARD_INDEX" class="card-body">
      <form [formGroup]="cardForm">
        <div class="mb-3 d-flex align-items-end">
          <h4 class="flex-grow-1 mb-0">Frage</h4>
          <button class="btn btn-light border shadow-none text-primary" (click)="onDeleteCardClicked()">
            Frage Löschen
          </button>
        </div>

        <!-- viewRegion for card-->
        <form formGroupName="viewRegion">
          <div class="form-group row">
            <label for="cardViewRegionId" class="col-sm-2 col-form-label">Region für Startansicht</label>
            <div class="col-sm-10">
              <ng-select [multiple]="false" formControlName="regionId" id="cardViewRegionId">
                <ng-option *ngFor="let region of caseSpec.regions" [value]="region.id">{{ region.id }}</ng-option>
              </ng-select>
            </div>
          </div>
        </form>

        <!-- Question -->
        <div class="form-group row">
          <label for="inputFrontTitle" class="col-sm-2 col-form-label">Frage / Aufforderung</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="inputFrontTitle" formControlName="frontTitle" />
          </div>
        </div>

        <!-- Hint -->
        <div class="form-group row">
          <label for="inputHint" class="col-sm-2 col-form-label">Hinweistext</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="inputHint" formControlName="hint" />
          </div>
        </div>

        <!-- Answer -->
        <div class="form-group row">
          <label for="inputBackText" class="col-sm-2 col-form-label">Lösungstext</label>
          <div class="col-sm-10">
            <textarea class="form-control" id="inputBackText" formControlName="backText" rows="3"></textarea>
          </div>
        </div>

        <!-- Tags -->
        <div class="form-group row">
          <label for="cardTags" class="col-sm-2 col-form-label">Tags</label>
          <div class="col-sm-10">
            <ng-select [multiple]="true" [addTag]="onAddTag.bind(this)" id="cardTags" formControlName="tags" />
          </div>
        </div>

        <!-- Solutions-->
        <h4 class="mt-4">Lösungen</h4>
        <div *ngFor="let control of this.solutionsControls(); index as i" formArrayName="solutions" class="card mb-2">
          <div class="card-body d-flex">
            <form [formGroupName]="i" class="flex-grow-1 pr-3">
              <div *ngIf="this.solutionsControls().length >= 2" class="form-group row">
                <label for="inputSolutionText" class="col-sm-2 col-form-label">Text</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="inputSolutionText" formControlName="text" />
                </div>
              </div>
              <div *ngIf="this.solutionsControls().length >= 2" class="form-group row">
                <label for="inputSolutionHint" class="col-sm-2 col-form-label">Hinweis</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="inputSolutionHint" formControlName="hint" />
                </div>
              </div>
              <div class="form-group row mb-0">
                <label for="solutionViewRegionId" class="col-sm-2 col-form-label">Klickregionen</label>
                <div class="col-sm-10">
                  <ng-select [multiple]="true" formControlName="regionIds" id="solutionViewRegionId">
                    <ng-option *ngFor="let region of caseSpec.regions" [value]="region.id">{{ region.id }}</ng-option>
                  </ng-select>
                </div>
              </div>
            </form>

            <button class="btn btn-light border shadow-none text-primary" (click)="onDeleteSolutionClicked(i)">
              Löschen
            </button>
          </div>
        </div>

        <button class="btn btn-light border shadow-none w-100 text-primary" (click)="onAddSolutionClicked()">
          Lösung hinzufügen
          <i class="ml-2 bi bi-plus-circle-fill"></i>
        </button>

        <!-- Followups -->
        <h4 class="mt-4">Folgetexte</h4>
        <div
          *ngFor="let control of this.followupsControls(); index as i"
          formArrayName="backFollowups"
          class="card mb-2">
          <div class="card-body d-flex">
            <form [formGroupName]="i" class="flex-grow-1 pr-3">
              <div class="form-group row">
                <label for="inputFollowupText" class="col-sm-2 col-form-label">Text</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="inputFollowupText" formControlName="text" />
                </div>
              </div>

              <form formGroupName="viewRegion">
                <div class="form-group row">
                  <label for="followUpViewRegionId" class="col-sm-2 col-form-label">Ansicht</label>
                  <div class="col-sm-10">
                    <ng-select [multiple]="false" formControlName="regionId" id="followUpViewRegionId">
                      <ng-option *ngFor="let region of caseSpec.regions" [value]="region.id">{{ region.id }}</ng-option>
                    </ng-select>
                  </div>
                </div>
              </form>
            </form>
            <button class="btn btn-light border shadow-none text-primary" (click)="onDeleteFollowupClicked(i)">
              Löschen
            </button>
          </div>
        </div>

        <button class="btn btn-light border shadow-none w-100 text-primary" (click)="onAddFollowupClicked()">
          Folgetext hinzufügen
          <i class="ml-2 bi bi-plus-circle-fill"></i>
        </button>

        <!-- CaseMarkers -->
        <h4 class="mt-4 mb-2">Verfolgungsmarker</h4>
        <div
          *ngFor="let control of this.caseMarkersControls(); index as i"
          formArrayName="caseMarkers"
          class="card mb-2">
          <div class="card-body d-flex">
            <form [formGroupName]="i" class="flex-grow-1 pr-3">
              <div class="form-group row">
                <label for="inputCaseMarkerText" class="col-sm-2 col-form-label">Text</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="inputCaseMarkerText" formControlName="text" />
                </div>
              </div>
              <div class="form-group row mb-0">
                <label for="caseMarkerViewRegionId" class="col-sm-2 col-form-label">Klickregion</label>
                <div class="col-sm-10">
                  <ng-select [multiple]="false" formControlName="regionId" id="caseMarkerViewRegionId">
                    <ng-option *ngFor="let region of caseSpec.regions" [value]="region.id">{{ region.id }}</ng-option>
                  </ng-select>
                </div>
              </div>
            </form>
            <button class="btn btn-light border shadow-none text-primary" (click)="onDeleteCaseMarkerClicked(i)">
              Löschen
            </button>
          </div>
        </div>
        <button class="btn btn-light border shadow-none w-100 text-primary" (click)="onAddCaseMarkerClicked()">
          Verfolgungsmarker hinzufügen
          <i class="ml-2 bi bi-plus-circle-fill"></i>
        </button>

        <!-- Tooltips -->
        <h4 class="mt-4 mb-2">Tooltips</h4>
        <div *ngFor="let control of this.tooltipsControls(); index as i" formArrayName="tooltips" class="card mb-2">
          <div class="card-body d-flex">
            <form [formGroupName]="i" class="flex-grow-1 pr-3">
              <div class="form-group row">
                <label for="inputTooltipText" class="col-sm-2 col-form-label">Text</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="inputTooltipText" formControlName="text" />
                </div>
              </div>
              <div class="form-group row mb-0">
                <label for="tooltipViewRegionId" class="col-sm-2 col-form-label">Klickregionen</label>
                <div class="col-sm-10">
                  <ng-select [multiple]="true" formControlName="regionIds" id="tooltipViewRegionId">
                    <ng-option *ngFor="let region of caseSpec.regions" [value]="region.id">{{ region.id }}</ng-option>
                  </ng-select>
                </div>
              </div>
            </form>
            <button class="btn btn-light border shadow-none text-primary" (click)="onDeleteTooltipClicked(i)">
              Löschen
            </button>
          </div>
        </div>
        <button class="btn btn-light border shadow-none w-100 text-primary" (click)="onAddTooltipClicked()">
          Tooltip hinzufügen
          <i class="ml-2 bi bi-plus-circle-fill"></i>
        </button>
      </form>
    </div>
  </div>
</div>
