export class CaseSpecModel {
  description: CaseDescription;
  tags: string[];
  titleCard: CaseTitleCard;
  regions: CaseRegion[];
  cards: CaseCard[];
  caseTooltips: CaseTooltip[];

  constructor(rawCase: any) {
    this.description = rawCase.description;
    this.tags = rawCase.tags ?? [];
    this.titleCard =
      rawCase.titleCard === undefined
        ? new CaseTitleCard({ viewRegion: null, backText: '' })
        : new CaseTitleCard(rawCase.titleCard);
    this.regions =
      rawCase.regions === undefined ? [] : rawCase.regions.map((rawRegion: any) => new CaseRegion(rawRegion));
    this.cards = rawCase.cards === undefined ? [] : rawCase.cards.map((rawCard: any) => new CaseCard(rawCard));
    this.caseTooltips =
      rawCase.tooltips === undefined ? [] : rawCase.tooltips.map((rawTooltip: any) => new CaseTooltip(rawTooltip));
  }
}

export interface CaseDescription {
  title: string;
  difficulty: number;
}

export class CaseTitleCard {
  viewRegion: CaseViewRegion | null;

  backText: string;

  constructor(rawTitleCard: any) {
    this.viewRegion = rawTitleCard.viewRegion ? new CaseViewRegion(rawTitleCard.viewRegion) : null;
    this.backText = rawTitleCard.backText;
  }
}

export class CaseCard {
  viewRegion: CaseViewRegion | null;
  frontTitle: string;
  hint: string;
  backText: string;
  solutions: CaseSolution[];
  tooltips: CaseTooltip[];
  caseMarkers: CaseMarker[];
  backFollowups: CaseFollowup[];
  tags: string[];

  constructor(rawCard: any) {
    this.viewRegion = rawCard.viewRegion ? new CaseViewRegion(rawCard.viewRegion) : null;
    this.frontTitle = rawCard.frontTitle;
    this.hint = rawCard.hint ?? '';
    this.backText = rawCard.backText ?? '';
    this.tooltips = rawCard.tooltips ?? [];
    this.solutions = rawCard.solutions ?? [];
    this.caseMarkers = rawCard.caseMarkers ?? [];
    this.backFollowups = rawCard.backFollowups ?? [];
    this.tags = rawCard.tags ?? [];
  }
}

export class CaseMarker {
  regionId: string;
  text: string;

  constructor(rawCaseTooltip: any) {
    this.regionId = rawCaseTooltip.regionId;
    this.text = rawCaseTooltip.text;
  }
}

export class CaseTooltip {
  regionIds: Array<string>;
  text: string;

  constructor(rawCaseTooltip: any) {
    this.regionIds = rawCaseTooltip.regionIds ? rawCaseTooltip.regionIds : [];
    this.text = rawCaseTooltip.text;
  }
}

export interface CaseSolution {
  regionIds: Array<string>;
  text: string | undefined;
}

export class CaseViewRegion {
  regionId: string;

  constructor(rawCaseViewRegion: any) {
    this.regionId = rawCaseViewRegion.regionId;
  }
}

export class CaseFollowup {
  text: string;
  viewRegion: CaseViewRegion | null;

  constructor(rawCaseFollowup: any) {
    this.text = rawCaseFollowup.text;
    this.viewRegion = rawCaseFollowup.viewRegion ? new CaseViewRegion(rawCaseFollowup.viewRegion) : null;
  }
}

export class CaseRegion {
  id: string;
  center: [number, number];
  radius: number;
  viewBounds: [number, number, number, number];

  constructor(rawRegion: any) {
    this.id = rawRegion.id;
    this.center = rawRegion.center;
    this.radius = rawRegion.radius;
    this.viewBounds = rawRegion.viewBounds ? rawRegion.viewBounds : [-1000, -1000, 1000, 1000];
  }
}
