import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MapService } from '../../services/map.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { MapModel } from '../../models/map.model';
import { FileUpload } from '../../../file-upload/models/file-upload.model';

@Component({
  selector: 'mima-edit-map-page',
  templateUrl: './edit-map-page.component.html',
  styleUrls: ['./edit-map-page.component.scss'],
})
export class EditMapPageComponent {
  previousMapModel: MapModel | undefined;

  form = new FormGroup({
    name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    sourcePdf: new FormControl<FileUpload | null>(null, { validators: [Validators.required] }),
    tags: new FormControl<string[]>([], { nonNullable: true, validators: [Validators.minLength(1)] }),
  });

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService,
    private router: Router
  ) {
    this.route.params.pipe(mergeMap((params: Params) => mapService.getMap(params.id))).subscribe(mapModel => {
      this.previousMapModel = mapModel;
      this.form.get('name')?.setValue(mapModel.name);
      this.form.get('sourcePdf')?.setValue(mapModel.sourcePdf);
      this.form.get('tags')?.setValue(mapModel.tags);
    });
  }

  onSubmit(): void {
    if (!this.previousMapModel) return;

    const updatedMapModel = {
      name: this.form.value.name,
      sourcePdf: this.form.value.sourcePdf ?? undefined,
      tags: this.form.value.tags,
    };

    this.mapService.updateMap(this.previousMapModel.id, updatedMapModel).subscribe({
      complete: () => this.router.navigate(['maps']).then(),
      error: error => console.log('error updating map: ' + error),
    });
  }

  onAddTag(term: string): string {
    return term;
  }
}
