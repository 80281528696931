import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MapService } from '../../services/map.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ComponentCanDeactivate } from '../../../editor-shared/guards/pending-changes.guard';
import { MapConfigModel } from '../../models/map-config.model';
import { CaseSpecModel } from '../../models/case-spec.model';
import { MapModel } from '../../models/map.model';

@Component({
  selector: 'mima-create-case-page',
  templateUrl: './create-case-page.component.html',
  styleUrls: ['./create-case-page.component.scss'],
})
export class CreateCasePageComponent implements ComponentCanDeactivate {
  mapConfig$: Observable<MapConfigModel | null>;
  unsavedChanges = true;

  caseSpec: CaseSpecModel = new CaseSpecModel({
    description: {
      title: 'Beispieltitel',
      difficulty: 2,
    },
    titleCard: {
      viewRegion: null,
      backText: '',
    },
    regions: [],
    cards: [],
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mapService: MapService
  ) {
    this.mapConfig$ = this.route.params.pipe(
      map((params: Params) => params.mapId),
      mergeMap((mapId: string) => mapService.getMap(Number.parseInt(mapId))),
      map((mapModel: MapModel) => mapModel.config)
    );
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !this.unsavedChanges;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any): boolean {
    return !this.unsavedChanges;
  }

  onCreateCaseClicked(): void {
    this.mapService.createCase(this.route.snapshot.params.mapId, this.caseSpec).subscribe(() => {
      this.unsavedChanges = false;
      this.router.navigate(['maps']).then();
    });
  }
}
