<div class="modal-header">
  <h4 class="modal-title pull-left">Fall importieren</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseClicked()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="form-group">
      <label for="inputCaseUpload">Import (.zip Datei)</label>
      <mima-file-upload id="inputCaseUpload" accept="application/zip" formControlName="caseZip"></mima-file-upload>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Importieren</button>
    <button type="button" class="btn btn-light border shadow-none text-primary" (click)="onCloseClicked()">
      Abbrechen
    </button>
  </div>
</form>
