<div class="h-100 w-100 d-flex flex-column">
  <div class="p-3 pb-4">
    <h3 class="mb-0">Regionen</h3>
  </div>
  <div style="overflow-y: scroll">
    <button
      *ngFor="let region of regions"
      [ngStyle]="{ 'background-color': region.id === selectedRegion?.id ? '#ff000044' : '#ffffff' }"
      class="btn btn-link text-dark text-left py-0 w-100 pl-3 pr-1 small text-nowrap text-truncate"
      (click)="onRegion(region)">
      {{ region.id }}
    </button>
  </div>
</div>
