import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private modalService: BsModalService) {}

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate()) {
      return true;
    } else {
      const confirm = new Subject<boolean>();
      this.modalService.show(ConfirmModalComponent, {
        class: 'modal-dialog-centered',
        initialState: {
          onConfirm: () => {
            confirm.next(true);
          },
          onAbort: () => {
            confirm.next(false);
          },
          titleText: 'Seite verlassen',
          bodyText:
            'Wenn du die Seite jetzt verlässt gehen alle ungespeicherten Änderungen verloren. Bist du dir wirklich sicher?',
          confirmButtonText: 'Verlassen',
          abortButtonText: 'Abbrechen',
        },
      });
      return confirm.asObservable();
    }
  }
}
