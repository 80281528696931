import { Component, OnInit } from '@angular/core';
import { MapService } from '../../services/map.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../../editor-shared/components/confirm-modal/confirm-modal.component';
import { timer } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { PlayLinkModalComponent } from '../play-link-modal/play-link-modal.component';
import { ImportCaseModalComponent } from '../import-case-modal/import-case-modal.component';
import { MapCaseModel, MapModel } from '../../models/map.model';

@Component({
  selector: 'mima-map-list',
  templateUrl: './map-list-page.component.html',
  styleUrls: ['./map-list-page.component.scss'],
})
export class MapListPageComponent implements OnInit {
  maps: MapModel[] | null = null;

  collapsedCasesMapIds: Set<number> = new Set<number>();

  constructor(
    private mapService: MapService,
    private router: Router,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    timer(1000 * 60, 1000 * 60).subscribe(() => {
      if (this.maps?.find(map => map.state == 'PROCESSING' || map.state == 'QUEUED')) {
        this.updateMaps();
      }
    });

    this.updateMaps();
  }

  updateMaps() {
    this.mapService.getAllMaps().subscribe(maps => (this.maps = maps));
  }

  getMapPreviewImageUrl(map: MapModel): string {
    return environment.backendUrl + '/file/' + map?.config?.baseUrl + '/2/1/1.png';
  }

  onCreateNewCardClicked(): void {
    this.router.navigate(['map', 'create']).then();
  }

  onDeleteClicked(map: MapModel): void {
    this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog-centered',
      initialState: {
        onConfirm: () => {
          this.mapService.deleteMap(map.id).subscribe(() => {
            this.updateMaps();
          });
        },
        titleText: 'Karte löschen',
        bodyText: `Bist du sicher dass du die Karte "${map.name}" löschen möchtest?`,
        confirmButtonText: 'Löschen',
        abortButtonText: 'Abbrechen',
      },
    });
  }

  onDeleteCaseClicked(map: MapModel, mapCase: MapCaseModel): void {
    this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog-centered',
      initialState: {
        onConfirm: () => {
          this.mapService.deleteCase(map.id, mapCase.id).subscribe(() => {
            this.updateMaps();
          });
        },
        titleText: 'Fall löschen',
        bodyText: `Bist du sicher dass du den Fall "${mapCase.title}" der Karte "${map.name}" löschen möchtest?`,
        confirmButtonText: 'Löschen',
        abortButtonText: 'Abbrechen',
      },
    });
  }

  onEditCaseClicked(mapEntity: MapModel, caseEntity: MapCaseModel): void {
    this.router.navigate(['map', mapEntity.id, 'case', caseEntity.id]).then();
  }

  onEditClicked(mapEntity: MapModel): void {
    this.router.navigate(['map', mapEntity.id]).then();
  }

  onExportCaseClicked(mapEntity: MapModel, caseEntity: MapCaseModel): void {
    this.mapService.exportCase(mapEntity.id, caseEntity.id).subscribe(blob => {
      const downloadUrl = window.URL.createObjectURL(blob);
      const dynamicDownloadElement = document.createElement('a');
      dynamicDownloadElement.setAttribute('href', downloadUrl);
      dynamicDownloadElement.setAttribute('download', `${mapEntity.name} - ${caseEntity.title}.zip`);
      const event = new MouseEvent('click');
      dynamicDownloadElement.dispatchEvent(event);
    });
  }

  onCollapseCasesClicked(mapEntity: MapModel): void {
    if (this.collapsedCasesMapIds.has(mapEntity.id)) {
      this.collapsedCasesMapIds.delete(mapEntity.id);
    } else {
      this.collapsedCasesMapIds.add(mapEntity.id);
    }
  }

  onImportCaseClicked(mapEntity: MapModel): void {
    this.modalService.show(ImportCaseModalComponent, {
      class: 'modal-dialog-centered',
      initialState: {
        mapId: mapEntity.id,
        onImport: () => {
          this.updateMaps();
        },
      },
    });
  }

  onExportMapClicked(mapEntity: MapModel): void {
    this.mapService.exportMap(mapEntity.id).subscribe(blob => {
      const downloadUrl = window.URL.createObjectURL(blob);
      const dynamicDownloadElement = document.createElement('a');
      dynamicDownloadElement.setAttribute('href', downloadUrl);
      dynamicDownloadElement.setAttribute('download', `${mapEntity.name}.zip`);
      const event = new MouseEvent('click');
      dynamicDownloadElement.dispatchEvent(event);
    });
  }

  onExportMapSourcePdfClicked(mapEntity: MapModel): void {
    this.mapService.exportMapSourcePdf(mapEntity.id).subscribe(blob => {
      const downloadUrl = window.URL.createObjectURL(blob);
      const dynamicDownloadElement = document.createElement('a');
      dynamicDownloadElement.setAttribute('href', downloadUrl);
      dynamicDownloadElement.setAttribute('download', `${mapEntity.name}_source_pdf.zip`);
      const event = new MouseEvent('click');
      dynamicDownloadElement.dispatchEvent(event);
    });
  }

  onShowPlayLink(map: MapModel, caseEntity: MapCaseModel) {
    this.modalService.show(PlayLinkModalComponent, {
      class: 'modal-dialog-centered',
      initialState: {
        mapId: map.id,
        caseId: caseEntity.id,
      },
    });
  }
}
