<main *ngIf="(routeData$ | async)?.fullScreen === true; else regularPage" class="full-screen">
  <router-outlet></router-outlet>
</main>

<ng-template #regularPage>
  <mima-header></mima-header>
  <main class="container regular-page">
    <router-outlet></router-outlet>
  </main>
  <mima-footer class="mt-auto"></mima-footer>
</ng-template>
