import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MapService } from '../../services/map.service';

@Component({
  selector: 'mima-import-case-modal',
  templateUrl: './import-case-modal.component.html',
  styleUrls: ['./import-case-modal.component.scss'],
})
export class ImportCaseModalComponent {
  form = new UntypedFormGroup({
    caseZip: new UntypedFormControl(null),
  });

  onImport: (() => void) | undefined;

  mapId: number | undefined;

  constructor(
    private modalRef: BsModalRef,
    private mapService: MapService
  ) {}

  onCloseClicked(): void {
    this.modalRef.hide();
  }

  onSubmit(): void {
    if (this.mapId) {
      this.mapService.importCase(this.mapId, this.form.value).subscribe({
        complete: () => {
          this.modalRef.hide();
          if (this.onImport) {
            this.onImport();
          }
        },
        error: error => {
          console.log('Error importing case: ' + error);
        },
      });
    }
  }
}
