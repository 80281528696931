<nav>
  <ol class="breadcrumb bg-transparent mt-1 mb-1 pl-0">
    <li class="breadcrumb-item"><a routerLink="/maps">Stadtkarten</a></li>
    <li class="breadcrumb-item active">Karte anlegen</li>
  </ol>
</nav>
<div class="bg-white shadow-sm rounded p-3">
  <h2 class="mb-4">Karte anlegen</h2>
  <div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="inputMapName">Name</label>
        <input type="text" class="form-control" id="inputMapName" formControlName="name" />
      </div>
      <div class="form-group">
        <label for="inputMapUpload">Karte (.pdf Datei)</label>
        <mima-file-upload id="inputMapUpload" accept="application/pdf" formControlName="sourcePdf"></mima-file-upload>
      </div>
      <button type="submit" class="mt-4 btn btn-primary" [disabled]="form.invalid">Anlegen</button>
    </form>
  </div>
</div>
