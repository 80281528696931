<div class="row p-3 pb-4">
  <h3 class="mb-0">{{ regionForm.value['id'] }}</h3>
  <button
    class="btn btn-light ml-3 border shadow-none text-primary"
    (click)="onDeleteClicked()"
    [disabled]="!allowDelete"
    [tooltip]="!allowDelete ? 'Region kann nicht gelöscht werden da sie noch von Karten referenziert wird.' : undefined"
    placement="top">
    Löschen
  </button>
  <button type="button" class="close ml-auto" (click)="onCloseClicked()">
    <span>&times;</span>
  </button>
</div>

<form [formGroup]="regionForm">
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col mb-3">
          <h4>Einstellungen</h4>
        </div>
      </div>
      <div class="form-group row">
        <label for="regionId" class="col-sm-3 col-form-label">Name</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="regionId" formControlName="id" />
          <small class="text-danger" *ngIf="regionForm.get('id')?.hasError('required')">
            Fehler: Dieses Feld wird benötigt.
          </small>
          <small class="text-danger" *ngIf="regionForm.get('id')?.hasError('duplicate')">
            Fehler: Name schon in Verwendung.
          </small>
        </div>
      </div>
      <div class="form-group row">
        <label for="regionRadius" class="col-sm-3 col-form-label">Radius</label>
        <div class="col-sm-9">
          <input type="number" step="10" class="form-control" id="regionRadius" formControlName="radius" />
          <small class="text-danger" *ngIf="this.regionForm.get('radius')?.hasError('min')">
            Fehler: Radius ist zu klein.
          </small>
        </div>
      </div>
      <div class="form-group row">
        <label for="regionLongitude" class="col-sm-3 col-form-label">X</label>
        <div class="col-sm-9">
          <input type="number" step="10" class="form-control" id="regionLongitude" formControlName="long" />
        </div>
      </div>
      <div class="form-group row">
        <label for="regionLatitude" class="col-sm-3 col-form-label">Y</label>
        <div class="col-sm-9">
          <input type="number" step="10" class="form-control" id="regionLatitude" formControlName="lat" />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col mb-3">
          <h4>Ansichtsrechteck</h4>
        </div>
      </div>
      <div class="form-group row">
        <label for="vb1Lng" class="col-sm-12 col-form-label">Ecke oben rechts</label>
        <div class="col-sm-6">
          <input type="number" step="10" class="form-control" id="vb1Lng" formControlName="vb1Lng" />
        </div>
        <div class="col-sm-6">
          <input type="number" step="10" class="form-control" id="vb1Lat" formControlName="vb1Lat" />
        </div>
      </div>
      <div class="form-group row">
        <label for="vb2Lng" class="col-sm-12 col-form-label">Ecke unten links</label>
        <div class="col-sm-6">
          <input type="number" step="10" class="form-control" id="vb2Lng" formControlName="vb2Lng" />
        </div>
        <div class="col-sm-6">
          <input type="number" step="10" class="form-control" id="vb2Lat" formControlName="vb2Lat" />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12">
          <button class="btn btn-light border shadow-none text-primary" (click)="onResetViewRectClicked()">
            Rechteck zurücksetzen
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
