import { Component, HostListener } from '@angular/core';
import { ComponentCanDeactivate } from '../../../editor-shared/guards/pending-changes.guard';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MapService } from '../../services/map.service';
import { mergeMap } from 'rxjs/operators';
import { MapModel } from '../../models/map.model';
import { CaseModel } from '../../models/case.model';
import { CaseSpecModel } from '../../models/case-spec.model';

@Component({
  selector: 'mima-edit-case-page',
  templateUrl: './edit-case-page.component.html',
  styleUrls: ['./edit-case-page.component.scss'],
})
export class EditCasePageComponent implements ComponentCanDeactivate {
  map$: Observable<MapModel | null>;
  case$: Observable<CaseModel | null>;

  loaded = false;
  unsavedChanges = true;

  caseSpec: CaseSpecModel = new CaseSpecModel({
    description: {
      title: 'Beispieltitel',
      difficulty: 2,
    },
    titleCard: {
      viewRegion: null,
      backText: '',
    },
    regions: [],
    cards: [],
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mapService: MapService
  ) {
    this.map$ = this.route.params.pipe(mergeMap((params: Params) => mapService.getMap(params.mapId)));

    this.case$ = this.route.params.pipe(mergeMap((params: Params) => mapService.getCase(params.mapId, params.caseId)));

    this.case$.subscribe(caseEntity => {
      if (caseEntity != null) {
        this.caseSpec = caseEntity.spec;
        this.loaded = true;
      } else {
        this.loaded = false;
      }
    });
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !this.unsavedChanges;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any): boolean {
    return !this.unsavedChanges;
  }

  onCaseSpecChange(caseSpec: CaseSpecModel): void {
    this.unsavedChanges = true;
  }

  onApplyChangesClicked(): void {
    this.mapService
      .updateCase(this.route.snapshot.params.mapId, this.route.snapshot.params.caseId, this.caseSpec)
      .subscribe(() => {
        this.unsavedChanges = false;
      });
  }

  onSaveChangesClicked(): void {
    this.mapService
      .updateCase(this.route.snapshot.params.mapId, this.route.snapshot.params.caseId, this.caseSpec)
      .subscribe(() => {
        this.unsavedChanges = false;
        this.router.navigate(['maps']).then();
      });
  }

  onBackClicked(): void {
    this.unsavedChanges = false;
    this.router.navigate(['maps']).then();
  }
}
