import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { CaseImportModel } from '../models/case-import.model';
import { MapModel } from '../models/map.model';
import { CaseSpecModel } from '../models/case-spec.model';
import { CaseModel } from '../models/case.model';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(private httpClient: HttpClient) {}

  getAllMaps(): Observable<MapModel[]> {
    return this.httpClient.get<MapModel[]>(`${environment.backendUrl}/maps`);
  }

  createMap(mapModel: MapModel): Observable<MapModel> {
    return this.httpClient.post<MapModel>(`${environment.backendUrl}/maps`, mapModel);
  }

  updateMap(mapId: number, mapModel: Partial<MapModel>): Observable<MapModel> {
    return this.httpClient.put<MapModel>(`${environment.backendUrl}/maps/${mapId}`, mapModel);
  }

  getMap(mapId: number): Observable<MapModel> {
    return this.httpClient.get<MapModel>(`${environment.backendUrl}/maps/${mapId}`);
  }

  deleteMap(mapId: number): Observable<void> {
    return this.httpClient.delete<void>(`${environment.backendUrl}/maps/${mapId}`);
  }

  createCase(mapId: number, caseSpec: CaseSpecModel): Observable<CaseModel> {
    return this.httpClient.post<CaseModel>(`${environment.backendUrl}/maps/${mapId}/cases`, {
      spec: JSON.stringify(caseSpec),
    });
  }

  updateCase(mapId: number, caseId: number, caseSpec: CaseSpecModel): Observable<CaseModel> {
    return this.httpClient.put<CaseModel>(`${environment.backendUrl}/maps/${mapId}/cases/${caseId}`, {
      spec: JSON.stringify(caseSpec),
    });
  }

  getAllCases(mapId: number): Observable<CaseModel[]> {
    return this.httpClient.get<any>(`${environment.backendUrl}/maps/${mapId}/cases`).pipe(
      map(rawCaseModels => {
        return rawCaseModels.map((rawCaseModel: any) => {
          return {
            id: rawCaseModel.id,
            spec: new CaseSpecModel(JSON.parse(rawCaseModel.spec)),
          };
        });
      })
    );
  }

  getCase(mapId: number, caseId: number): Observable<CaseModel> {
    return this.httpClient.get<any>(`${environment.backendUrl}/maps/${mapId}/cases/${caseId}`).pipe(
      map(rawCaseModel => {
        return {
          id: rawCaseModel.id,
          spec: new CaseSpecModel(JSON.parse(rawCaseModel.spec)),
        };
      })
    );
  }

  exportCase(mapId: number, caseId: number): Observable<Blob> {
    return this.httpClient
      .get<any>(`${environment.backendUrl}/maps/${mapId}/cases/${caseId}/export`, {
        responseType: 'arraybuffer' as 'json',
      })
      .pipe(
        map(data => {
          return new Blob([data], {
            type: 'application/zip',
          });
        })
      );
  }

  importCase(mapId: number, caseImportEntity: CaseImportModel): Observable<void> {
    return this.httpClient.post<void>(`${environment.backendUrl}/maps/${mapId}/import-case`, caseImportEntity);
  }

  deleteCase(mapId: number, caseId: number): Observable<void> {
    return this.httpClient.delete<void>(`${environment.backendUrl}/maps/${mapId}/cases/${caseId}`);
  }

  exportMap(mapId: number): Observable<Blob> {
    return this.httpClient
      .get<any>(`${environment.backendUrl}/maps/${mapId}/export`, {
        responseType: 'arraybuffer' as 'json',
      })
      .pipe(
        map(data => {
          return new Blob([data], {
            type: 'application/zip',
          });
        })
      );
  }

  exportMapSourcePdf(mapId: number): Observable<Blob> {
    return this.httpClient
      .get<any>(`${environment.backendUrl}/maps/${mapId}/export_sourcepdf`, {
        responseType: 'arraybuffer' as 'json',
      })
      .pipe(
        map(data => {
          return new Blob([data], {
            type: 'application/zip',
          });
        })
      );
  }
}
