import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CanActivateFn, Router } from '@angular/router';

export function roleGuard(requiredRole: string): CanActivateFn {
  return () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    if (authService.isLoggedIn()) {
      const userRoles = authService.getLoggedInUserRoles();
      if (userRoles?.indexOf(requiredRole) !== -1) {
        return true;
      }
    }

    return router.createUrlTree(['error']);
  };
}
