import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CaseRegion } from '../../models/case-spec.model';

function notOnOfValidator(notOneOf: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const duplicate = notOneOf.indexOf(control.value) !== -1;
    return duplicate
      ? {
          duplicate: { value: control.value },
        }
      : null;
  };
}

@Component({
  selector: 'mima-click-region-panel',
  templateUrl: './click-region-panel.component.html',
  styleUrls: ['./click-region-panel.component.scss'],
})
export class ClickRegionPanelComponent {
  // tslint:disable-next-line:variable-name
  private _takenIds: string[] = [];

  @Input() set region(region: CaseRegion | undefined) {
    if (region !== undefined) {
      this.regionForm.patchValue({
        id: region.id,
        radius: region.radius,
        lat: region.center[0],
        long: region.center[1],

        vb1Lat: region.viewBounds[0],
        vb1Lng: region.viewBounds[1],
        vb2Lat: region.viewBounds[2],
        vb2Lng: region.viewBounds[3],
      });
    }
  }

  @Input() set takenIds(takenIds: string[] | undefined) {
    if (takenIds !== undefined) {
      this._takenIds = takenIds;
    } else {
      this._takenIds = [];
    }

    this.regionForm.controls.id.setValidators([Validators.required, notOnOfValidator(this._takenIds)]);
    this.regionForm.controls.id.updateValueAndValidity({ emitEvent: false });
    this.enableDisableControls();
  }

  @Input() allowDelete = false;

  @Output() regionChanged = new EventEmitter<CaseRegion>();

  @Output() regionDeleteClicked = new EventEmitter<CaseRegion>();

  @Output() regionCloseClicked = new EventEmitter<void>();

  regionForm = new UntypedFormGroup({
    id: new UntypedFormControl('', [Validators.required, notOnOfValidator(this._takenIds)]),
    radius: new UntypedFormControl(100, [Validators.required, Validators.min(10)]),
    lat: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
    long: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),

    vb1Lat: new UntypedFormControl(0, Validators.required),
    vb1Lng: new UntypedFormControl(0, Validators.required),
    vb2Lat: new UntypedFormControl(0, Validators.required),
    vb2Lng: new UntypedFormControl(0, Validators.required),
  });

  formValueAsRegion(): CaseRegion {
    return new CaseRegion({
      id: this.regionForm.value.id,
      radius: this.regionForm.value.radius,
      center: [this.regionForm.value.lat, this.regionForm.value.long],
      viewBounds: [
        this.regionForm.value.vb1Lat,
        this.regionForm.value.vb1Lng,
        this.regionForm.value.vb2Lat,
        this.regionForm.value.vb2Lng,
      ],
    });
  }

  constructor(changeDetectorRef: ChangeDetectorRef) {
    this.regionForm.valueChanges.subscribe(() => {
      this.enableDisableControls();
      if (
        this.regionForm.controls.id.valid &&
        this.regionForm.controls.radius.valid &&
        this.regionForm.controls.lat.valid &&
        this.regionForm.controls.long.valid
      ) {
        this.regionChanged.emit(this.formValueAsRegion());
      }
      changeDetectorRef.detectChanges();
    });
  }

  enableDisableControls(): void {
    let singleControlInvalid = false;

    for (const [, control] of Object.entries(this.regionForm.controls)) {
      if (control.invalid) {
        singleControlInvalid = true;
        for (const [, otherControl] of Object.entries(this.regionForm.controls)) {
          if (otherControl !== control) {
            otherControl.disable({ emitEvent: false });
          }
        }
        control.enable({ emitEvent: false });
      }
    }

    if (!singleControlInvalid) {
      for (const [, control] of Object.entries(this.regionForm.controls)) {
        control.enable({ emitEvent: false });
      }
    }
  }

  onDeleteClicked(): void {
    this.regionDeleteClicked.emit(this.formValueAsRegion());
  }

  onCloseClicked(): void {
    this.regionCloseClicked.emit();
  }

  onResetViewRectClicked(): void {
    this.regionForm.patchValue({
      vb1Lat: -this.regionForm.value.radius,
      vb1Lng: -this.regionForm.value.radius,
      vb2Lat: this.regionForm.value.radius,
      vb2Lng: this.regionForm.value.radius,
    });
  }
}
