import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export const oAuthGuard = async () => {
  const authService = inject(AuthService);
  if (authService.isLoggedIn()) {
    return true;
  }
  authService.login();
  return false;
};
